var $appMain = $("#kingineGoMain");
var $appSub = $("#kingineGoSub");
//var $getApp = $("#kingineBox").html();
var flagMain = true;
var flagSub = true;

function previewMain (input) {

    var imageCount = input.files.length;
    var loop = 0;

    if (flagMain) {
        $appMain.after("<ul id='kingineWalletMain'></ul>");
        flagMain = false;
    }

    while (loop < imageCount) {
        if(input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e) {
                //$app.css("background-image", "url("+e.target.result+")");
                // $("#kingineWallet").prepend($("<li></li>").fadeIn(100)).delay(3000).queue(function() {
                //     $("#kingineWallet li:nth-child("+loop+")").css("background-image", "url("+e.target.result+")");
                // });
                $("#kingineWalletMain").prepend("<li style='background-image: url("+e.target.result+")'></li>");
            }
            reader.readAsDataURL(input.files[loop]);
        }
        loop++;
    }

}

function previewSub (input) {

    var imageCount = input.files.length;
    var loop = 0;

    if (flagSub) {
        $appSub.after("<ul id='kingineWalletSub'></ul>");
        flagSub = false;
    }

    while (loop < imageCount) {
        if(input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function(e) {
                //$app.css("background-image", "url("+e.target.result+")");
                // $("#kingineWallet").prepend($("<li></li>").fadeIn(100)).delay(3000).queue(function() {
                //     $("#kingineWallet li:nth-child("+loop+")").css("background-image", "url("+e.target.result+")");
                // });
                $("#kingineWalletSub").prepend("<li style='background-image: url("+e.target.result+")'></li>");
            }
            reader.readAsDataURL(input.files[loop]);
        }
        loop++;
    }

}


$appMain.change(function () {
    $("#kingineWalletMain li").remove();
    previewMain(this);
});

$appSub.change(function () {
    $("#kingineWalletSub li").remove();
    previewSub(this);
});
