/*
$("#addCollectionValue li a").click(function(e) {
    e.preventDefault();

    //  variables
    var $collection = $(this).text();
    var $input = $('#productCollection');
    var $val = $input.val();

    if ($val) {
        $input.val($collection+ "," +$val);
        console.log("has value");
    } else {
        $input.attr("value", $collection);
        console.log("no value");
    }

});

$("#addCategoryValue li a").click(function(e) {
    e.preventDefault();

    //  variables
    var $collection = $(this).text();
    var $input = $('#productCategory');
    var $val = $input.val();

    if ($val) {
        $input.val($collection+ "," +$val);
        console.log("has value");
    } else {
        $input.attr("value", $collection);
        console.log("no value");
    }

});
*/
