$(document).ready(function() {

    //start - product gallery
    $('.button-remove').click(function() {
        var itemPos = $(this).attr('item-pos');
        var $input = $('#deleteImage');
        var speed = 500;

        // Add value to a hidden input
        if ($input.val() != '') {
            $input.val($input.val() + ',' + itemPos);
        } else {
            $input.val(itemPos);
        }

        $(this).parent().fadeOut(speed, function() {
            $(this).remove();
        });

    });

    $('.button-primary').click(function() {
        $('.button-remove').css('display', 'block');
        $(this).parent().find('.button-remove').css('display', 'none');
        $('.button-primary i').removeClass('active');
        $(this).find('i').addClass('active');
        $('#primaryImage').val($(this).attr('item-pos'));
        console.log($('#primaryImage').val());
    });
    //end - product gallery

    //==============================================================================

    //start - edit menu

    /*
    var menuPos = 0;
    var menuVal;

    */
    $('#sender, #receiver, #suber').sortable({
        connectWith: '.connectedSortable'
    });

    /*
    function addVal(){
        $('#receiver li')
        .each(function(index){
            menuVal = $(this).text();
            $(this).attr({
                'menu-pos': menuPos,
                'menu-val': menuVal
            });
            menuPos++
        })
        .promise()
        .done(function(){
            menuPos = 0;
        });
    }

    $('#editMenu').find('#sender, #receiver').bind('mousemove hover', function(){
        console.log('oy');
        addVal();
    });
    */

    var MenuModule = (function() {

        var menuItemVal; //temp variable
        var inputWomen; //used by input women
        var inputMen; //used by input men
        var inputKids; // used by input kid
        var isFirst = true;

        var _revertFirst = function() {
            isFirst = true;
        };

        var insertInputValue = function() {
            _insertValue01();
            _insertValue02();
            _insertValue03();
        };

        var _insertValue01 = function() {
            $('#editMenu ul[main-menu="women"] li').each(function() {
                menuItemVal = $(this).attr('sub-menu');
                if (isFirst) {
                    inputWomen = menuItemVal;
                    isFirst = false;
                } else {
                    inputWomen = inputWomen + '_' + menuItemVal;
                }
                menuItemVal = '';
            });

            $('#editMenu input[name="women"]').val(inputWomen);
            _revertFirst();
        };

        var _insertValue02 = function() {
            $('#editMenu ul[main-menu="men"] li').each(function() {
                menuItemVal = $(this).attr('sub-menu');
                if (isFirst) {
                    inputMen = menuItemVal;
                    isFirst = false;
                } else {
                    inputMen = inputMen + '_' + menuItemVal;
                }
                menuItemVal = '';
            });

            $('#editMenu input[name="men"]').val(inputMen);
            _revertFirst();
        };

        var _insertValue03 = function() {
            $('#editMenu ul[main-menu="kids"] li').each(function() {
                menuItemVal = $(this).attr('sub-menu');
                if (isFirst) {
                    inputKids = menuItemVal;
                    isFirst = false;
                } else {
                    inputKids = inputKids + '_' + menuItemVal;
                }
                menuItemVal = '';
            });

            $('#editMenu input[name="kids"]').val(inputKids);
            _revertFirst();
        };

        return {
            insertInputValue: insertInputValue
        };

    })();

    //edit menu
    $('.menu-save #save').click(function() {
        MenuModule.insertInputValue();
    });

    //DISABLE
    // $("html").on("contextmenu",function(e){
    //    return false;
    // });
});
